/* ======================== */
/* 🌞 Light Mode (Default) */
/* ======================== */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f4f8;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

/* Center page content */
.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  text-align: center;
  padding: 20px;
}

/* Navbar */
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #007bff;
  padding: 10px 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

nav a {
  text-decoration: none;
  color: white;
  margin: 0 10px;
  font-size: 16px;
}

nav a:hover {
  text-decoration: underline;
}

/* Footer */
footer {
  padding: 20px;
  text-align: center;
  background-color: #6a0dad;
  color: white;
}

/* Buttons */
button {
  cursor: pointer;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  background-color: #333;
}

button:hover {
  background-color: #555;
}

/* Improve link contrast in light mode */
a {
  color: #007bff;
}

/* Loading Spinner */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


a:hover {
  color: #0056b3;
}

/* ======================== */
/* 🌙 Dark Mode Styles */
/* ======================== */

.dark-mode {
  background-color: #121212;
  color: #f0f4f8;
}

/* Dark Mode for Page Content */
.dark-mode .page-container {
  background-color: #1a1a1a;
  color: #f0f4f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  text-align: center;
  padding: 20px;
  transition: background-color 0.3s, color 0.3s;
}

/* Dark Mode for Navbar */
.dark-mode nav {
  background-color: #333 !important;
}

/* Dark Mode for Footer */
.dark-mode footer {
  background-color: #222 !important;
}

/* Dark Mode for Links */
.dark-mode a {
  color: #a0cfff;
}

.dark-mode a:hover {
  color: #66b2ff;
}

/* Dark Mode for Buttons */
.dark-mode button {
  background-color: #555;
}

.dark-mode button:hover {
  background-color: #777;
}

/* Improve Input Fields in Dark Mode */
.dark-mode input,
.dark-mode textarea {
  background-color: #333;
  color: #f0f4f8;
  border: 1px solid #777;
}

.dark-mode input::placeholder,
.dark-mode textarea::placeholder {
  color: #aaa;
}

/* Improve spacing for smaller screens */
@media (max-width: 768px) {
  .page-container {
    padding: 10px;
  }

  h1 {
    font-size: 2rem;
  }

  nav {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  nav a {
    display: block;
    margin: 5px 0;
  }

  footer {
    padding: 15px;
    font-size: 14px;
  }
}

/* Bot Status Page */
.status-container {
  text-align: center;
  margin-top: 40px;
}

.status-container p {
  font-size: 18px;
  margin: 8px 0;
}

.status-online {
  color: #28a745;
  font-weight: bold;
}

.status-offline {
  color: #dc3545;
  font-weight: bold;
}
